<template>
    <v-container>
        <v-row>
            <v-col>
                <recently-added-units />
            </v-col>
            <v-col>
                <recently-added-stations />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
import RecentlyAddedUnits from '@/components/RecentlyAddedUnits.vue';
import RecentlyAddedStations from '@/components/RecentlyAddedStations.vue';

export default {
    name: 'Dashboard',

    components: {
        RecentlyAddedUnits,
        RecentlyAddedStations
    }
};
</script>
