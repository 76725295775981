<template>
    <v-card>
        <v-card-title>Recently Added Stations</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="6" v-for="station in stations" :key="station.id" class="ma-0 pa-1">
                    <v-card outlined class="ma-0 pa-0">
                        <v-list-item three-line dense>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <router-link :to="{ name: 'station', params: { id: station.id } }">
                                        {{ station.aemoId }}
                                    </router-link>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <span>Bidder </span>
                                    <span class="text--primary">{{ station.bidder.name }}</span>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <span>Owner </span>
                                    <span class="text--primary">{{ station.owner.name }}</span>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <span>Trader </span>
                                    <span class="text--primary">{{ station.trader.name }}</span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-avatar tile class="mb-auto mt-1 caption text--secondary">
                                {{ station.id }}
                            </v-list-item-avatar>

                        </v-list-item>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="js">
import axios from 'axios';

export default {
    name: 'RecentlyAddedStations',

    data() {
        return {
            loading: true,
            stations: []
        };
    },

    methods: {
        async load() {
            this.loading = true;
            const response = await axios.get('/api/stations?order=mostRecent&limit=16');
            this.stations = response.data;
            this.loading = false;
        }
    },

    async created() {
        await this.load();
    }
};
</script>
