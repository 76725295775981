<template>
    <v-card>
        <v-card-title>Recently Added Units</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="6" v-for="unit in units" :key="unit.id" class="ma-0 pa-1">
                    <v-card outlined class="ma-0 pa-0">
                        <v-list-item three-line dense>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <router-link :to="{ name: 'unit', params: { id: unit.id } }">{{ unit.aemoId }}
                                    </router-link>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <span>Fuel Type </span>
                                    <span class="text--primary">{{ unit.fuelType.name }}</span>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <span>Gen Tech </span>
                                    <span class="text--primary">{{ unit.generationTechnology.name }}</span>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <span>Turbine Supplier </span>
                                    <span class="text--primary">{{ unit.oem.name }}</span>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <span>Participant Type </span>
                                    <span class="text--primary">{{ unit.participantType.name }}</span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-avatar tile class="mb-auto mt-1 caption text--secondary">
                                {{ unit.id }}
                            </v-list-item-avatar>
                        </v-list-item>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="js">
import axios from 'axios';

export default {
    name: 'RecentlyAddedUnits',

    data() {
        return {
            loading: true,
            units: []
        };
    },

    methods: {
        async load() {
            this.loading = true;
            const response = await axios.get('/api/units?order=mostRecent&limit=12');
            this.units = response.data;
            this.loading = false;
        }
    },

    async created() {
        await this.load();
    }
};
</script>
